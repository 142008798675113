<template>
  <b-card no-body border-variant="light">
    <b-card-header header-class="pl-0 pr-0">
      <b-container fluid>
        <b-row align-v="center">
          <b-col sm="auto">
            <h4 class="mb-0 text-left">
              <span class="mr-2">Withdrawals</span>
              <b-badge variant="secondary" pill>
                {{ totalRows }}
              </b-badge>
            </h4>
          </b-col>
        </b-row>
        <b-row v-if="statsResult" cols-sm="auto" align-v="center" class="mt-3">
          <b-col class="cards-wrapper" v-if="statsResult.newRequestsCount">
            <b-button class="p-0 w-100" @click="onStatsFilterValueChange('New')">
              <stats-card title="New requests" type="success" :sub-title="statsResult.newRequestsCount">
                <template slot="footer">
                  <span class="text-nowrap">Total by requests:</span>
                  <div v-for="total in statsResult.totalNewRequests" :key="total">
                    <span class="text-nowrap text-success font-weight-bold">{{ total }}</span>
                  </div>
                </template>
              </stats-card>
            </b-button>
          </b-col>
          <b-col class="cards-wrapper" v-if="statsResult.approvedRequestsCount">
            <b-button class="p-0 w-100" @click="onStatsFilterValueChange('Approved')">
              <stats-card title="Approved requests" type="success" :sub-title="statsResult.approvedRequestsCount">
                <template slot="footer">
                  <span class="text-nowrap">Total by requests:</span>
                  <div v-for="total in statsResult.totalApprovedRequests" :key="total">
                    <span class="text-nowrap text-success font-weight-bold">{{ total }}</span>
                  </div>
                </template>
              </stats-card>
            </b-button>
          </b-col>
          <b-col class="cards-wrapper" v-if="statsResult.declinedRequestsCount">
            <b-button class="p-0 w-100" @click="onStatsFilterValueChange('Declined')">
              <stats-card title="Declined requests" type="success" :sub-title="statsResult.declinedRequestsCount">
                <template slot="footer">
                  <span class="text-nowrap">Total by requests:</span>
                  <div v-for="total in statsResult.totalDeclinedRequests" :key="total">
                    <span class="text-nowrap text-success font-weight-bold">{{ total }}</span>
                  </div>
                </template>
              </stats-card>
            </b-button>
          </b-col>
          <b-col class="cards-wrapper" v-if="statsResult.inProgressRequestsCount">
            <b-button class="p-0 w-100" @click="onStatsFilterValueChange('In progress')">
              <stats-card title="Requests in progress" type="success" :sub-title="statsResult.inProgressRequestsCount">
                <template slot="footer">
                  <span class="text-nowrap">Total by requests:</span>
                  <div v-for="total in statsResult.totalInProgressRequests" :key="total">
                    <span class="text-nowrap text-success font-weight-bold">{{ total }}</span>
                  </div>
                </template>
              </stats-card>
            </b-button>
          </b-col>
          <b-col class="cards-wrapper" v-if="statsResult.requestsCount">
            <b-button class="p-0 w-100" @click="onStatsFilterValueChange()">
              <stats-card title="Sum numbers of requests" type="success" :sub-title="statsResult.requestsCount">
                <template slot="footer">
                  <span class="text-nowrap">Total by requests:</span>
                  <div v-for="total in statsResult.totalRequests" :key="total">
                    <span class="text-nowrap text-success font-weight-bold">{{ total }}</span>
                  </div>
                </template>
              </stats-card>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col sm="auto">
            <b-form-datepicker v-model="selectedDatesRange.dateAndTimeStart" @input="onDateFilterValueChange" />
          </b-col>
          -
          <b-col sm="auto">
            <b-form-datepicker v-model="selectedDatesRange.dateAndTimeEnd" @input="onDateFilterValueChange" />
          </b-col>
        </b-row>
      </b-container>
    </b-card-header>
    <common-data-selection-grid
      :ref="refKeyCommonDataSelectionGrid"
      :data-result-list="withdrawalsResultList"
      :fields-list="fields"
      :has-details="true"
      :is-busy="false"
      :total-rows="totalRows"
      :per-page="perPage"
      :is-filtered="true"
      :filters-list="sourceFiltersList"
      :filters-model="filtersModel"
      :multiple-filters-model="multipleFiltersModel"
      :need-per-page="true"
      @load-clicked="onLoadClicked"
      @sort-clicked="onSortClicked"
      @pg-clicked="onPgClicked"
      @srch-clicked="onSrchClicked"
      @update-clicked="onUpdateRequest"
      @comment-clicked="onAddComment"
      @multiple-filter-clicked="onMultipleFilterClicked"
      @details-clicked="onDetailsClicked"
    >
    </common-data-selection-grid>
    <withdrawals-dlg ref="WithdrawalsDlg"></withdrawals-dlg>
  </b-card>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
import CommonDataSelectionGrid from "../../../components/grids/common-data-selection-grid";
import StatsCard from "../../../components/cards/stats-card";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import { convertList } from "../helpers/checks-converter";
import WithdrawalsDlg from "../../../components/popups/withdrawals-dlg";
import qs from "qs";

const WithdrawalsRepository = RepositoryFactory.get(RepositoryName.checks);
const UsersRepository = RepositoryFactory.get(RepositoryName.users);
const refKeyCommonDataSelectionGrid = "common-data-selection-grid";

export default {
  name: "WithdrawalsList",
  components: {
    WithdrawalsDlg,
    StatsCard,
    CommonDataSelectionGrid
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,

      statsResult: null,
      isLoading: false,
      currentPage: 0,
      perPage: 20,
      filter: null,
      totalRows: 1,
      sortBy: "",
      sortDesc: false,
      sorting: "ByDateAndTimeDesc",

      selectedDatesRange: {
        dateAndTimeStart: "",
        dateAndTimeEnd: ""
      },
      selectedFilters: [],
      filtersModel: [
        {
          key: "checkStatus",
          selected: []
        },
        {
          key: "currency",
          selected: []
        }
      ],
      multipleFiltersModel: {
        currency: [],
        checkStatus: [],
        paymentMethods: []
      },
      sourceFiltersList: [],
      withdrawalsResultList: [],
      resultData: []
    };
  },
  async mounted() {
    await this.onStatsFilterValueChange("New");
    await this.getFiltersList();
    await this.getStatistic();
  },
  computed: {
    fields() {
      return [
        {
          key: "dateAndTime",
          label: "Request Date&time",
          sortable: true,
          sortDirection: "asc",
          tdClass: "dateField",
          type: "date"
        },
        {
          key: "clientId",
          label: "ID code",
          sortable: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "transactionNumber",
          label: "Transaction Number",
          sortable: true
        },
        {
          key: "paymentMethod",
          label: "Payment method",
          sortable: true,
          multipleFiltered: true
        },
        {
          key: "amount",
          label: "Requested amount",
          sortable: true
        },
        {
          key: "fee",
          label: "Fee",
          sortable: true
        },
        {
          key: "amountReceived",
          label: "Actual amount",
          sortable: true
        },
        {
          key: "currency",
          label: "Currency",
          sortable: true,
          multipleFiltered: true
        },
        {
          key: "status.status",
          label: "Transaction status",
          sortable: true
        },
        {
          key: "description",
          label: "Description",
          sortable: true
        },
        {
          key: "checkStatus",
          label: "Check status",
          sortDirection: "desc",
          multipleFiltered: true
        },
        {
          key: "editWithdraw",
          label: "Actions",
          sortDirection: "asc",
          thStyle: { width: "7rem !important" }
        }
      ];
    }
  },
  methods: {
    async onLoadClicked(value) {
      this.perPage = value;

      await this.getWithdrawals().catch(error => {
        console.error(error);
      });
    },
    async onSortClicked(value, sortDesc) {
      this.sortBy = value;
      this.sortDesc = sortDesc;

      await this.getWithdrawals().catch(error => {
        console.error(error);
      });
    },
    async onPgClicked(value) {
      this.currentPage = value;
      await this.getWithdrawals().catch(error => {
        console.error(error);
      });
    },
    async onSrchClicked(textValue) {
      this.filter = textValue;

      await this.getWithdrawals().catch(error => {
        console.error(error);
      });
      await this.getStatistic().catch(error => {
        console.error(error);
      });
    },
    async onDetailsClicked(item) {
      await this.getSumSubInfo(item.userId, item.details).catch(error => {
        console.error(error);
      });
    },
    async onMultipleFilterClicked(value, textMask) {
      this.multipleFiltersModel = value;
      this.filter = textMask;

      if (!Object.keys(value).find(x => value[x].length > 0) && !textMask)
        this.selectedDatesRange.dateAndTimeEnd = this.selectedDatesRange.dateAndTimeStart = "";

      await this.getWithdrawals().catch(error => {
        console.error(error);
      });
      await this.getStatistic().catch(error => {
        console.error(error);
      });
    },
    async onDateFilterValueChange() {
      await this.getWithdrawals().catch(error => {
        console.error(error);
      });
      await this.getStatistic().catch(error => {
        console.error(error);
      });
    },
    async onStatsFilterValueChange(status) {
      this.multipleFiltersModel["checkStatus"] = [status];

      await this.getWithdrawals().catch(error => {
        console.error(error);
      });
      await this.getStatistic().catch(error => {
        console.error(error);
      });
    },
    async getSumSubInfo(userId, details) {
      if (details.find(x => x.title === "SumSub")) return;

      let axiosResponse = await UsersRepository.getUserSumSubInfo(userId);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      let kycResponse = axiosResponse.data;
      details.splice(5, 0, {
        title: "SumSub",
        description: kycResponse
          ? {
              sumSubInfo: kycResponse
            }
          : ""
      });
    },
    async getWithdrawals() {
      await this.getRequestResult();
    },
    async getRequestResult() {
      switch (this.sortBy) {
        case "dateAndTime": {
          if (this.sortDesc) this.sorting = "ByDateAndTimeDesc";
          else this.sorting = "ByDateAndTimeAsc";
          break;
        }
        case "clientId": {
          if (this.sortDesc) this.sorting = "ByClientIdDesc";
          else this.sorting = "ByClientIdAsc";
          break;
        }
        case "email": {
          if (this.sortDesc) this.sorting = "ByEmailDesc";
          else this.sorting = "ByEmailAsc";
          break;
        }
        case "transactionNumber": {
          if (this.sortDesc) this.sorting = "ByTransactionIdDesc";
          else this.sorting = "ByTransactionIdAsc";
          break;
        }
        case "paymentMethod": {
          if (this.sortDesc) this.sorting = "ByPaymentMethodDesc";
          else this.sorting = "ByPaymentMethodAsc";
          break;
        }
        case "amount": {
          if (this.sortDesc) this.sorting = "ByAmountDesc";
          else this.sorting = "ByAmountAsc";
          break;
        }
        case "status.status": {
          if (this.sortDesc) this.sorting = "ByTransactionStatusDesc";
          else this.sorting = "ByTransactionStatusAsc";
          break;
        }
      }

      if (this.selectedDatesRange.dateAndTimeEnd) {
        var dateEnd = new Date(this.selectedDatesRange.dateAndTimeEnd);
        dateEnd.setDate(dateEnd.getDate() + 1);
      }

      let filter = {
        params: {
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
          mask: this.filter,
          dateFrom: this.selectedDatesRange.dateAndTimeStart
            ? new Date(this.selectedDatesRange.dateAndTimeStart)
            : null,
          dateTo: this.selectedDatesRange.dateAndTimeEnd ? dateEnd : null,
          sorting: this.sorting,
          statuses: this.multipleFiltersModel["checkStatus"],
          currencies: this.multipleFiltersModel["currency"],
          paymentMethods: this.multipleFiltersModel["paymentMethod"]
        },
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      };

      this.isLoading = true;
      let axiosResponse = await WithdrawalsRepository.getWithdraw(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let withdrawalsResponse = axiosResponse.data;

      this.resultData = withdrawalsResponse.items;
      this.isLoading = false;

      this.withdrawalsResultList = convertList(this.resultData);
    },
    async onAddComment(id, comment) {
      if (!comment) return;

      let request = {
        value: comment
      };

      let axiosResponse = await WithdrawalsRepository.addComment(id, request);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      await this.getWithdrawals();
      this.flashMessage.success({
        message: "Success",
        blockClass: "msg-block"
      });
    },
    async onUpdateRequest(id, transferTransactionId, status, comment) {
      let request = {
        id: id,
        transferTransactionId: transferTransactionId,
        comment: comment ?? null,
        status: status
      };

      let axiosResponse = await WithdrawalsRepository.update(request);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      await this.getWithdrawals();
      await this.getStatistic();

      this.flashMessage.success({
        message: "Success",
        blockClass: "msg-block"
      });
    },
    async getStatistic() {
      if (this.selectedDatesRange.dateAndTimeEnd) {
        var dateEnd = new Date(this.selectedDatesRange.dateAndTimeEnd);
        dateEnd.setDate(dateEnd.getDate() + 1);
      }

      let filter = {
        params: {
          dateFrom: this.selectedDatesRange.dateAndTimeStart
            ? new Date(this.selectedDatesRange.dateAndTimeStart)
            : null,
          dateTo: this.selectedDatesRange.dateAndTimeEnd ? dateEnd : null
        }
      };

      let axiosResponse = await WithdrawalsRepository.getStatistic(filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        return;
      }

      this.statsResult = axiosResponse.data;
    },
    //#region Filters
    async getFiltersList() {
      this.isLoading = true;
      let axiosResponse = await WithdrawalsRepository.getChecksFilterInfo();

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      if (!axiosResponse.data) return;

      let filterInfo = axiosResponse.data;
      this.isLoading = false;

      this.sourceFiltersList = [];
      this.sourceFiltersList.push(await this.filters_getCheckStatuses(filterInfo));
      this.sourceFiltersList.push(await this.filters_getCurrencies(filterInfo));
      this.sourceFiltersList.push(await this.filters_getPaymentMethods(filterInfo));
    },
    async filters_getCheckStatuses(filtersInfo) {
      if (filtersInfo.checkStatuses) {
        return {
          key: "checkStatus",
          items: filtersInfo.checkStatuses
        };
      }
    },
    async filters_getCurrencies(filtersInfo) {
      if (filtersInfo.currencies) {
        return {
          key: "currency",
          items: filtersInfo.currencies
        };
      }
    },
    async filters_getPaymentMethods(filtersInfo) {
      if (filtersInfo.paymentMethods) {
        return {
          key: "paymentMethod",
          items: filtersInfo.paymentMethods
        };
      }
    }
    //#endregion
  }
};
</script>
