import moment from "moment";

export function convertList(sourceData, isAml = false) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.id = item.id;
      container.checkId = item.checkId;
      container.dateAndTime = moment(String(item.dateAndTime)).utc().format("DD.MM.YYYY HH:mm:ss");
      container.dateAndTimeUpdated = item.dateAndTimeUpdated
        ? moment(String(item.dateAndTimeUpdated)).utc().format("DD.MM.YYYY HH:mm:ss")
        : null;
      container.statusChangeDate = item.statusChangeDate
        ? moment(String(item.statusChangeDate)).utc().format("DD.MM.YYYY HH:mm:ss")
        : null;

      container.ticketId = item.ticketId;
      container.clientId = item.clientId;
      container.client = item.client;
      container.email = item.email;
      container.isEmailConfirmed = item.isEmailConfirmed;
      container.transactionNumber = item.transactionNumber;
      container.transferTransactionId = item.transferTransactionId;
      container.sourceTradingAccountNumber = item.sourceTradingAccountNumber;
      container.destinationTradingAccountNumber = item.destinationTradingAccountNumber;
      container.paymentMethod = item.paymentMethod;
      container.paymentAccountDetails = item.paymentAccountDetails;
      container.amount = item.amount;
      container.amountReceived = item.amountReceived;
      container.fee = item.fee;
      container.currency = item.currency;
      container.status = item.transactionStatus;
      container.transactionInfoUrl = item.transactionInfoUrl;
      container.description = item.billyErrorMessage;
      container.isCheck = true;

      container.reasonForTrigger = item.reasonForTrigger;
      if (item.statusChangeDate) {
        container.statusChangeDate = moment(String(item.statusChangeDate)).utc().format("DD.MM.YYYY HH:mm:ss");
      }
      container.manager = item.manager;

      container.checkStatus = item.checkStatus || item.status;
      container.riscScorePartOne = item.riscScorePartOne;
      container.userId = item.userId;
      container.withdrawFields = item.withdrawFields;
      container.canApprove = container.canDecline =
        container.checkStatus === "New" || container.checkStatus === "InProgress";

      container.details = [
        {
          title: "Client ID",
          description: container.userId
        },
        {
          title: "Client",
          description: container.client
        },
        ...(container.sourceTradingAccountNumber
          ? [
              {
                title: "From Login ID",
                description: container.sourceTradingAccountNumber
              }
            ]
          : []),
        ...(container.destinationTradingAccountNumber
          ? [
              {
                title: "To Login ID",
                description: container.destinationTradingAccountNumber
              }
            ]
          : []),
        {
          title: " Link to BillyTools",
          key: "transactionInfoUrl",
          description: container.transactionInfoUrl
        },
        ...(!container.manager && container.checkStatus === "Approved"
          ? [
              {
                title: "Back Office manager",
                key: "approveTitle",
                description: "Auto-approved"
              }
            ]
          : [
              {
                title: "Back Office manager",
                key: "manager",
                description: container.manager
              }
            ]),
        {
          title: "Request Date&time",
          description: container.dateAndTime
        },
        ...(container.statusChangeDate
          ? [
              {
                title: "Processed Date&time",
                description: container.statusChangeDate
              }
            ]
          : []),
        ...(container.dateAndTimeUpdated
          ? [
              {
                title: "Update Date&time",
                description: container.dateAndTimeUpdated
              }
            ]
          : [])
      ];

      if (item.comments?.length > 0) {
        container.details.splice(4, 0, {
          title: "Comments",
          key: "comments",
          description: item?.comments
            ? item?.comments
                .map(
                  x => `${x.admin?.name} (${moment(String(x.date)).utc().format("DD.MM.YYYY HH:mm:ss")}): ${x.comment}`
                )
                .join("\n")
            : ""
        });
      }

      if (!isAml) {
        container.details.splice(2, 0, {
          title: "Payment account details",
          key: "paymentAccountDetails",
          description: [
            ...(item?.withdrawFields ? item?.withdrawFields : []),
            { key: "payment_account_details", value: container.paymentAccountDetails }
          ]
        });
      }

      return container;
    });
  }
}
