<template>
  <popup-modal ref="popup">
    <h4 class="mb-4"><b>Change status</b></h4>
    <b-container fluid class="popup-content">
      <b-row class="mb-2">
        <b-col sm="3" class="text-sm-left">
          <label><b>Status:</b></label>
        </b-col>
        <b-col>
          <b-form-select v-model="status" :options="sourceStatuses" :state="status !== ''"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3" class="text-sm-left">
          <label><b>Comment:</b></label>
        </b-col>
        <b-col>
          <b-form-textarea class="textarea" rows="5" v-model="comment" :state="comment.trim().length > 0">
          </b-form-textarea>
        </b-col>
      </b-row>
    </b-container>
    <div class="btns mt-4">
      <b-button variant="primary" @click="onSave" :disabled="comment.trim().length < 1 || status === ''">Save</b-button>
      <b-button variant="dark" class="ml-2" @click="_cancel">Cancel</b-button>
    </div>
    <confirm-dialogue :show-confirm="true" ref="confirmDialogue"></confirm-dialogue>
  </popup-modal>
</template>

<script>
import PopupModal from "./popup-modal.vue";
import ConfirmDialogue from "./confirm-dlg";
import { RepositoryFactory, RepositoryName } from "../../api/repositories/repository-factory";
import { checkUserPermissions } from "../../modules/sections/helpers/permissions-checker";

const AmlRepository = RepositoryFactory.get(RepositoryName.checks);

export default {
  name: "AmlDlg",
  components: {
    PopupModal,
    ConfirmDialogue
  },
  data() {
    return {
      id: "",
      ticketId: "",
      comment: "",
      status: "",
      sourceStatuses: ["Approved", "Declined"],
      resultItems: {
        isOk: false
      }
    };
  },
  methods: {
    async onSave() {
      const dlgResult = await this.$refs.confirmDialogue.show({
        title: "Are you sure you want to approve request?",
        message: "",
        okButton: "Confirm"
      });

      if (dlgResult.isOk) {
        this.resultItems = {
          isOk: true
        };
      }

      await this.onUpdateClicked();

      this.resolvePromise(this.resultItems);
      this.$refs.popup.close();
    },
    show(opts = {}) {
      this.id = opts.id;
      this.ticketId = opts.ticketId;

      this.$refs.popup.open();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _cancel() {
      this.resetState();
      this.$refs.popup.close();
      this.resolvePromise(false);
    },
    resetState() {
      this.id = "";
      this.ticketId = "";
      this.comment = "";
      this.status = "";
    },
    async onUpdateClicked() {
      await this.updateDepositStatus().catch(error => {
        console.error(error);
      });
    },
    async updateDepositStatus() {
      let request = {
        id: this.id,
        transactionNumber: null,
        amlCheckNumber: this.ticketId,
        comment: this.comment,
        status: this.status
      };

      let axiosResponse = await AmlRepository.update(request);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.flashMessage.success({
        message: "Success",
        blockClass: "msg-block"
      });

      // await this.getRequestResult(); ??? refresh table
    }
  }
};
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popup-content {
  @include media-breakpoint-tablet() {
    width: 450px;
  }
}
</style>
